import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  Edit,
  SelectInput,
  TextInput,
  Button ,
  Create,
  SimpleForm,
  CreateButton ,
  TopToolbar,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  FormDataConsumer,
  useListContext, required,NumberInput,BooleanInput,SelectField,  FunctionField, ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, Toolbar, SaveButton,
  useNotify,
  useRedirect,
  DateField  , 
  Filter, 
  downloadCSV,
  DateInput,
  SimpleFormIterator,
  ArrayInput,BooleanField,
  NumberField,
  ReferenceField,
  useDataProvider,
  RadioButtonGroupInput,
  useUpdate,
  DateTimeInput
} from 'react-admin';

import { useEffect } from 'react';

import jsonExport from 'jsonexport/dist';

import {useHistory} from 'react-router-dom';
import { useForm, useFormState } from "react-final-form";

import { AccordionSection  } from '@react-admin/ra-form-layout';


import { makeStyles } from '@material-ui/core/styles';

import Divider from '@mui/material/Divider';


import { useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';

import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CloseIcon from '@mui/icons-material/Close';


import { green, orange, deepOrange, red } from '@mui/material/colors';

import RichTextInput from 'ra-input-rich-text';


import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import GavelIcon from '@material-ui/icons/Gavel';
import Save from '@material-ui/icons/Save';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';

import { ColorInput } from 'react-admin-color-input';

import { DocsList, DocsListShow } from './docs';
import { targets, modules, choicesClassroomsType, targetsEmails,emailsStatus, apiOrigin, apiDefaultLanguage, apiUrl, choicesTypeUser, choicesDonationsUsersPaymentMethod, choicesDonationsUsersPublicPaymentMethod, choicesDonationsUsersStatus } from './../common/constants';
import { dateOnlyToCatalan, subscriptionModule, setActivePermissionData, structArrayToIdList, DateTimeField, dateAndTimeToCatalan, DatagridNoResults } from './../common/utils';  

import { CityInput } from './../components/Inputs';
import { ExportButton } from './../components/ExportButton';
import { BackLink } from './../components/BackLink';

import { SendEmailIconStatus } from './../components/Emails';
import DownloadIcon from '@material-ui/icons/GetApp';

import { RenderBoolean, UserFullNameTxt } from '../components/User';
import Tooltip from '@mui/material/Tooltip';

import Box from '@material-ui/core/Box';

import { Link } from 'react-router-dom';

import { validateMaxLength, validateInteger, validateEmail, validateDoubleGreatZero, validateDouble } from './../components/Validator';



export const DonationsUsersList = (props) => {
  const translate = useTranslate();  
  const history = useHistory();
  const [info, setInfo] = useState();
  const dataProvider = useDataProvider();
    
  let current_path = history && history.location && history.location.pathname ? history.location.pathname : '';
    
  let basePath='/donations_users'; 
  let resource = 'donations_users';
  let strRedirect = 'donations_users';
    
  let donations_projects_id = 0;
  if (props.match.params.id) donations_projects_id = props.match.params.id;
  
  let report = 0;
  if (current_path.includes('/reports')) report = 1;
  
  let year = 0;
  if (props.match.params.year) year = props.match.params.year;
  
  let backLink = '/donations_projects';
  backLink = report ? '/reports' + backLink : backLink;
    
  useEffect(() => {
    
    if (donations_projects_id > 0)
    {
      dataProvider.getOne('donations_projects', { id: donations_projects_id, report: report, associations_id: localStorage.getItem('associations_id') })
      .then(({ data }) => {     
        setInfo(data);
      })
      .catch(error => {           
      });
    }
       
  }, []);
    
  return (
    <>   
    { current_path.includes('/donations_users') ? <BackLink link={backLink} title={ info ? info.name : null } /> : null }
    <ResourceContextProvider value={resource}>
      <List 
        basePath={basePath}   
        filter={{ report: report, associations_id: localStorage.getItem('associations_id'), donations_projects_id : donations_projects_id, year: year, only_transfer: current_path.includes('/donations_transfer_validations') ? true : false }}  
        actions={ <ListActions create={donations_projects_id && (report || (info && !info.donations_projects_payment_systems_fac))} year={year} donations_projects_id={donations_projects_id} report={report} />}    
        bulkActionButtons={false}          
        perPage={1000}   
        pagination={false}
        sort={{ field: 'dateTime', order: 'DESC' }} 
        label=""  
        title=""  
        resource={resource}
        filters={ <MyFilter /> } 
        filterDefaultValues={ { q: "", only_accepted: current_path.includes('/donations_transfer_validations') ? false : true, only_pending: current_path.includes('/donations_transfer_validations') ? true : false, only_certificate_not_sent: false }}
        
      >        
        <Datagrid           
          expand={<DonationsUsersDetails />} 
          empty={ <DatagridNoResults /> } >  
          <FunctionField label={translate('donations_users.order_id')} render={record => getOrderId(record.id) } sortable={false} />
          <FunctionField label={translate('donations_users.donations_users_rel')} render={record => getOrderId(record.donations_users_rel) } sortable={false} />
          <DateTimeField source="date_time" label={translate('donations_users.date_time')} sortable={false} /> 
          <TextField source="complete_name" label={translate('common.name')} sortable={false} /> 
          <TextField source="email" label={translate('donations_users.email')} sortable={false} />  
          <TextField source="nif" label={translate('donations_users.nif')} sortable={false} />            
          <NumberField source="amount" label={translate('donations_users.amount')} sortable={false} locales="es-ES" options={{ style: 'currency', currency: 'EUR' }} /> 
          { !current_path.includes('/donations_transfer_validations') ?
          <FunctionField render={record => translate('donations_users.payment_method_' + record.payment_method) } label={translate('donations_users.payment_method')}  sortable={false}  />
          : null}
          <FunctionField label={translate('donations_users.status')} render={record => <RenderStatus status={record.status} /> } sortable={false}  />          
          { current_path.includes('/donations_users') ? 
            <FunctionField label={translate('donations_users.email_sent_confirmation_title')} render={record => 
            report || !record.donations_projects_payment_systems_fac ?
              <SendEmailIconStatus record={record} target={targetsEmails.DONATIONS_USERS_CONFIRMATION}  /> 
            : null
            } />
          : null }
          
          { !current_path.includes('/donations_transfer_validations') ?
          <FunctionField label={translate('donations_users.certificate')} render={record => 
            <>
            {( report || !record.donations_projects_payment_systems_fac) && record.status == 1 && record.want_certificate ? 
              <DownloadCertificate donations_users_id={record.id} /> 
            : null }
            
            {( report || !record.donations_projects_payment_systems_fac) ?
            <SendEmailIconStatus record={record} target={targetsEmails.DONATIONS_USERS_CERTIFICATE}  />                 
            : null }
            
            </>
          } sortable={false}  />
          : null }
          
          <FunctionField label={translate('donations_users.actions')} render={record => 
            (report || !record.donations_projects_payment_systems_fac) ?
            <>
            <EditDonationButton record={record} donations_projects_id={donations_projects_id} />
            { (current_path.includes('/donations_transfer_validations') || current_path.includes('/donations_users')) && record.status == 1 && !record.donations_users_rel ? <ConfirmButton record={record} periodical={true} /> : null }
            { (current_path.includes('/donations_transfer_validations') || current_path.includes('/donations_users')) ? <ConfirmButton record={record} periodical={false} /> : null }            
            </> 
            : null
          } sortable={false}  />
          
        </Datagrid>        
      </List>
    </ResourceContextProvider>
    </>
  );
};

export const getOrderId = (id) => id ? 'D-' + id.toString().padStart(8, '0') : '';

const RenderStatus = (props) => {
    const translate = useTranslate(); 

    if (props.status === 0) 
        return <Tooltip title={translate('donations_users.status_0')}>
            <Avatar sx={{ bgcolor: orange[500] , width: 30, height: 30 }} ><PendingActionsIcon /></Avatar>
        </Tooltip>;
    else if (props.status === 1) 
        return <Tooltip title={translate('donations_users.status_1')}>
                <Avatar sx={{ bgcolor: green[500] , width: 30, height: 30 }} ><DoneIcon /></Avatar>
        </Tooltip>;
    else if (props.status === 2)
        return <Tooltip title={translate('donations_users.status_2')}>
            <Avatar sx={{ bgcolor: red[500] , width: 30, height: 30 }} ><CloseIcon /></Avatar>
        </Tooltip>;
    else
        return null;
}

export const ConfirmButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('donations_users/' + localStorage.getItem('associations_id') + '/' + props.record.id + '/confirm/' + props.periodical );
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
  
  const today = new Date();
          
  const handleClick = () => {        
    setShowDialog(true);        
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
  
  let title = props.periodical ? translate('donations_users.copy_title') : translate('donations_users.validate_transfer');
    
  return (
    <>            
      <Button onClick={handleClick} label={props.periodical ? translate('donations_users.copy') : translate('donations_users.validate')} className="">
        { props.periodical ? <ContentCopyIcon />: <DoneIcon />}
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('donations_users.validate_transfer')}
      >
        <DialogTitle>{ title + ' ' + getOrderId(props.record.id)} </DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ associations_id : localStorage.getItem('associations_id'), donations_users_id : props.record.id, status: 1, send_confirmation: true, amount: props.record.amount }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <FormDataConsumer>
                {({ formData, ...rest }) => 
                <Grid container spacing={2}>
                  <Grid item xs={4}>     
                    <DateTimeInput fullWidth source="date_time" label={translate('donations_users.date_time')} validate={[required()]} initialValue={today} />   
                  </Grid>
                  <Grid item xs={4}>                    
                    <NumberInput source="amount" label={translate('donations_users.amount')} parse={value => value ? value : 0 } validate={[required(),validateDoubleGreatZero(translate('common.validation.number'))]} />            
                  </Grid>
                  <Grid item xs={4}> 
                    <SelectInput fullWidth label={translate('donations_users.status')} source="status" choices={choicesDonationsUsersStatus(translate)} validate={[required()]} />
                  </Grid>                   
                  <Grid item xs={12}>                                               
                    {formData.status == 1 ? 
                    <BooleanInput fullWidth source="send_confirmation" label={translate('donations_users.send_confirmation')} />
                    : null }
                  </Grid>
                </Grid>
                }
                </FormDataConsumer>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

export const DownloadCertificate = ({ record, donations_users_id, ...props}) => {
    
  const translate = useTranslate(); 
  const notify = useNotify();

  const handleAction = async () => {

    let headers = new Headers({ Accept: 'application/json' });
    headers.set('Access-Control-Allow-Origin', apiOrigin );
    headers.set('Authorization', localStorage.getItem('auth'));
    headers.set('Accept-Language', apiDefaultLanguage);
    headers.set('Role', localStorage.getItem('active_permission'));

    const result = await fetch(apiUrl + '/donations_users/' + donations_users_id + '/certificate/' + localStorage.getItem('associations_id'), { 
      headers: headers
    });
    
    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);
    
    var tempLink = document.createElement('a');
    tempLink.href = href;
    tempLink.setAttribute('download', translate('donations_users.pdf_certificate_filename'));
    tempLink.click();
    
    notify(translate('donations_users.pdf_certificate_ok'),"success");
  };
  
  return (
    <Tooltip title={translate('donations_users.pdf_download')}> 
      <DownloadIcon onClick={handleAction} />
    </Tooltip>
  );

};


const MyFilter = (props) => {
    const translate = useTranslate(); 
    const history = useHistory();
    
    let current_path = history && history.location && history.location.pathname ? history.location.pathname : '';
    
    return (
    <Filter {...props}>         
        <TextInput label={translate('common.search')} source="q" alwaysOn validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} resettable /> 
        { !current_path.includes('/donations_transfer_validations') ? <BooleanInput label={translate('donations_users.only_accepted')} source="only_accepted" alwaysOn /> : null }
        { current_path.includes('/donations_transfer_validations') ? <BooleanInput label={translate('donations_users.only_pending')} source="only_pending" alwaysOn /> : null }
        { current_path.includes('/reports') && !current_path.includes('/donations_transfer_validations') ? <BooleanInput label={translate('donations_users.only_certificate_not_sent')} source="only_certificate_not_sent" alwaysOn /> : null }
    </Filter>
);
};

export const DonationsUsersDetails = ({ id, record, resource, ...props }) => {
  const translate = useTranslate(); 
        
  return (
    <div className="usersClassroomsExpand">        
      <Grid container spacing={2}>
        <Grid item xs={3}>   
          <TextFieldShow source={dateAndTimeToCatalan(record.date_time, translate)} label={translate('donations_users.date_time')} />
        </Grid>
        <Grid item xs={3}>   
          <TextFieldShow source={record.donations_projects_name} label={translate('donations_users.donations_projects_name')} />
        </Grid>
        <Grid item xs={3}>   
          <TextFieldShow source={record.associations_name} label={translate('donations_users.associations_name')} />
        </Grid>
        <Grid item xs={3}>   
          <TextFieldShow source={record.amount} label={translate('donations_users.amount')} />
        </Grid>        
        <Grid item xs={3}>   
          <TextFieldShow source={<UserFullNameTxt record={record} />} label={translate('donations_users.first_name')} />
        </Grid> 
        <Grid item xs={3}>   
          <TextFieldShow source={record.email} label={translate('donations_users.email')} />
        </Grid> 
        <Grid item xs={3}>   
          <TextFieldShow source={record.phone} label={translate('donations_users.phone')} />
        </Grid> 
        <Grid item xs={3}>   
          <TextFieldShow source={record.nif} label={translate('donations_users.nif')} />
        </Grid>    
        <Grid item xs={3}>   
          <TextFieldShow source={record.address} label={translate('donations_users.address')} />
        </Grid> 
        <Grid item xs={3}>   
          <TextFieldShow source={record.zip} label={translate('donations_users.zip')} />
        </Grid> 
        <Grid item xs={3}>   
          <TextFieldShow source={record.city} label={translate('donations_users.city')} />
        </Grid> 
        <Grid item xs={3}>   
          <TextFieldShow source={record.country} label={translate('donations_users.country')} />
        </Grid>         
        <Grid item xs={3}>   
          <TextFieldShow source={translate('donations_users.payment_method_'+record.payment_method)} label={translate('donations_users.payment_method')} />
        </Grid>         
        {record.payment_method == 2 ?
        <Grid item xs={3}>   
          <TextFieldShow source={record.transaction_id} label={translate('donations_users.transaction_id')} />
        </Grid> : <Grid item xs={3}></Grid> }
        <Grid item xs={3}>   
          <TextFieldShow source={translate('donations_users.status_' + record.status)} label={translate('donations_users.status')} />
        </Grid>  
        <Grid item xs={3}>
            <TextFieldShow source={<RenderBoolean source={record.want_certificate} />} label={translate('donations_users.want_certificate')} />   
        </Grid>
        <Grid item xs={12}>   
          <TextFieldShow source={record.comment} label={translate('donations_users.comment')} />
        </Grid>         
      </Grid>
      
    </div>
  );
};

const exporter = (items, translate, notify) => {
    
  const itemsForExport = items.map(item => {
    var itemForExport = {
        id: getOrderId(item.id),
        donations_users_rel: getOrderId(item.donations_users_rel),
        date_time: item.date_time,
        donations_projects_id: item.donations_projects_id,
        donations_projects_name: item.donations_projects_name,
        associations_id: item.associations_id,
        associations_name: item.associations_name,
        user_type: translate('users.user_type_' + item.user_type),
        first_name: item.first_name,
        last_name_1: item.last_name_1,
        last_name_2: item.last_name_2,
        email: item.email,
        want_certificate: item.want_certificate,
        nif: item.nif,
        address: item.address,
        zip: item.zip,
        city: item.city,
        country: item.country,
        phone: item.phone,
        payment_method: translate('donations_users.payment_method_' + item.payment_method),        
        amount: item.amount != null ? item.amount.toFixed(2).replace('.', ',') : '',    
        status: translate('donations_users.status_'+item.status),
        transaction_id: item.transaction_id,
        comment: item.comment,        
        confirmation_status: item.confirmation_status == emailsStatus.SENT_TO_SMTP_OK ? 'Sí' : 'No',
        confirmation_date_time: item.confirmation_date_time,
        send_status: item.send_status == emailsStatus.SENT_TO_SMTP_OK ? 'Sí' : 'No',
        send_date_time: item.send_date_time
    };
    return itemForExport;
  });
  jsonExport(itemsForExport, {
    headers: ['id',
        'donations_users_rel',
        'date_time',
        'donations_projects_id',
        'donations_projects_name',
        'associations_id',
        'associations_name'
,'user_type'
,'first_name'
,'last_name_1'
,'last_name_2'
,'email'
,'want_certificate'
,'nif'
,'address'
,'zip'
,'city'
,'country'
,'phone'
,'payment_method'
,'amount'
,'status'
,'transaction_id'
,'comment'
,'confirmation_status'
,'confirmation_date_time'
,'send_status'
,'send_date_time'
],
    rename: [translate('common.id'),translate('donations_users.donations_users_rel'), translate('donations_users.date_time'), translate('donations_users.donations_projects_id'),translate('donations_users.donations_projects_name'),
        translate('donations_users.associations_id'), translate('donations_users.associations_name'), 
        translate('donations_users.user_type'),translate('donations_users.first_name'),translate('donations_users.last_name_1'),
        translate('donations_users.last_name_2'),translate('donations_users.email'),translate('donations_users.want_certificate'),
        translate('donations_users.nif'),translate('donations_users.address'),
        translate('donations_users.zip'),translate('donations_users.city'),translate('donations_users.country'),translate('donations_users.phone'),
        translate('donations_users.payment_method'),translate('donations_users.amount'),translate('donations_users.status'),
        translate('donations_users.transaction_id'),translate('donations_users.comment'), 
        translate('donations_users.confirmation_status'), translate('donations_users.confirmation_date_time'), translate('donations_users.send_status'), translate('donations_users.send_date_time') ],        
        
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('donations_users.exporter_file_name') ); 
    notify(translate('common.download_ok'),"success");
  });
};


const ListActions = (props) => {
    const translate = useTranslate(); 
    const history = useHistory();
    
    let current_path = history && history.location && history.location.pathname ? history.location.pathname : '';
        
    let filterExport = { 
        associations_id: localStorage.getItem('associations_id'),    
        year: props.year,
        q: props.filterValues && props.filterValues.q ? props.filterValues.q : '',
        only_accepted: props.filterValues && props.filterValues.only_accepted ? props.filterValues.only_accepted : false,
        only_pending: props.filterValues && props.filterValues.only_pending ? props.filterValues.only_pending : false,
        only_transfer: current_path.includes('/donations_transfer_validations') ? true : false,
        only_certificate_not_sent: props.filterValues && props.filterValues.only_certificate_not_sent ? props.filterValues.only_certificate_not_sent : false,
        donations_projects_id: props.donations_projects_id,
        report : props.report
    };
    
    return (
    <TopToolbar>  
        { props.create ? <CreateDonationButton {...props} /> : null }   
        <ExportButton maxResults="1000000" filterValues={filterExport} alwaysEnabled={false} exporter={exporter} sort={{ field: 'dateTime', order: 'DESC' }} /> 
    </TopToolbar>
    );
};


               
export const EditDonationButton = ({ ...props }) => {

    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();    
    const translate = useTranslate();  
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    
    const history = useHistory();
    
    let current_path = history && history.location && history.location.pathname ? history.location.pathname : '';

    const [update, { loading, error }] = useUpdate();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
            
        update(
            { 
                type: 'update', 
                resource: 'donations_users', 
                payload : { id: values.id, data : values }
            },
            {
                onSuccess: ({ data }) => {
                    setShowDialog(false);   
                    
                    if (!props.record.donations_projects_payment_systems_fac) { redirect('/donations_users/' + props.donations_projects_id ); refresh(); return; }
                    
                    if (!current_path.includes('/donations_certificates') && !current_path.includes('/donations_transfer_validations'))
                    {
                        redirect('/reports/donations_users/' + props.donations_projects_id );
                    }                    
                    refresh();
                },
                onFailure: ( error ) => {
                    notify(error.message, 'error');
                }
            }
        );
    };    
    
    return (
        <>            
            <Button onClick={handleClick} label={translate('donations_users.edit')}>
              <EditIcon />                
            </Button>

            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('donations_users.status_form')}
                disableEnforceFocus
            >
                <DialogTitle>{translate('donations_users.edit')}</DialogTitle>

                <FormWithRedirect
                    resource="remittances_users_items"
                    save={handleSubmit}
                    initialValues={{ associations_id: localStorage.getItem('associations_id') , ...props.record }}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                            <DialogContent>
                              <DonationsUsersForm />
                            </DialogContent>  
                            <DialogActions>
                                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
}

export const CreateDonationButton = ({ donations_projects_id , ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('donations_users');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const redirect = useRedirect();
        
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {                    
          setShowDialog(false);
          refresh();        
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={translate('donations_users.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('donations_users.create')}
      >
        <DialogTitle>{translate('donations_users.create')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ associations_id: localStorage.getItem('associations_id'), donations_projects_id: donations_projects_id, status: 1, user_type: 0, payment_method: 0, want_certificate: false }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
                            
                <DialogContent>
                  <DonationsUsersForm />
                </DialogContent>              
              
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

export const DonationsUsersForm = ({ ...props }) => {
    const translate = useTranslate();        
    return (<FormDataConsumer>
    {({ formData, ...rest }) => <>
                <Grid container spacing={1}>
                  <Grid item xs={8}>  
                    { props.public ? 
                        <RadioButtonGroupInput source="payment_method" choices={choicesDonationsUsersPublicPaymentMethod(translate)} optionText="name" optionValue="id" label={translate('donations_users.payment_method')}  validate={required()} />            
                    : 
                        <RadioButtonGroupInput source="payment_method" choices={choicesDonationsUsersPaymentMethod(translate)} optionText="name" optionValue="id" label={translate('donations_users.payment_method')}  validate={required()} />            
                    }
                  </Grid>
                  <Grid item xs={4}>                    
                    <NumberInput source="amount" label={translate('donations_users.amount')} parse={value => value ? value : 0 } validate={[required(),validateDoubleGreatZero(translate('common.validation.number'))]} />            
                  </Grid>
                </Grid>
                <Grid container spacing={1}>                  
                  <Grid item xs={12}>                    
                    <RadioButtonGroupInput source="user_type" choices={choicesTypeUser(translate)} optionText="name" optionValue="id" label={translate('donations_users.user_type')}  validate={required()} />            
                  </Grid>
                </Grid>                
                <Grid container spacing={1}>                                     
                  <Grid item xs={formData.user_type == 0 ? 4 : 12}>                                               
                    <TextInput fullWidth source="first_name" label={translate('donations_users.first_name')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                  </Grid>
                  {formData.user_type == 0 ? 
                  <Grid item xs={4}>                                               
                    <TextInput fullWidth source="last_name_1" label={translate('donations_users.last_name_1')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                  </Grid> : null }
                  {formData.user_type == 0 ? 
                  <Grid item xs={4}>                                               
                    <TextInput fullWidth source="last_name_2" label={translate('donations_users.last_name_2')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                  </Grid> : null } 
                </Grid> 
                <Grid container spacing={1}>                                     
                  <Grid item xs={12}>                                               
                    <TextInput fullWidth source="email" label={translate('donations_users.email')} parse={value => value} validate={[validateMaxLength(100,translate('common.validation.maxLength')),validateEmail()]} />
                  </Grid>                  
                </Grid> 
                <Grid container spacing={1}>        
                  <Grid item xs={12}>                                               
                    <BooleanInput fullWidth source="want_certificate" label={translate('donations_users.want_certificate')} />
                  </Grid>
                </Grid>
                {formData.want_certificate ? 
                <>
                <Grid container spacing={1}>        
                  <Grid item xs={6}>                                               
                    <TextInput fullWidth source="nif" label={translate('donations_users.nif')} validate={[required(),validateMaxLength(10,translate('common.validation.maxLength'))]} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput fullWidth source="phone" label={translate('donations_users.phone')} validate={[validateMaxLength(15,translate('common.validation.maxLength'))]} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput fullWidth source="address" label={translate('donations_users.address')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                  </Grid>                  
                  <Grid item xs={6}>
                    <TextInput fullWidth source="zip" label={translate('donations_users.zip')} validate={[required(),validateMaxLength(5,translate('common.validation.maxLength'))]} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput fullWidth source="city" label={translate('donations_users.city')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput fullWidth source="country" label={translate('donations_users.country')} validate={[required(),validateMaxLength(50,translate('common.validation.maxLength'))]} />
                  </Grid>
                </Grid> 
                </>
                : null }
                <Grid container spacing={1}>        
                  <Grid item xs={12}>
                    <TextInput fullWidth multiline source="comment" label={translate('donations_users.comment')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />   
                  </Grid>
                </Grid>
              </> }
    </FormDataConsumer>);
        
};

const MyActions = ({ basePath, data, resource }) => {
    
  let backLink = '/donations_users';
  return <BackLink link={backLink} title={ data && data.name ? data.name : null } />;
        
};

/*
export const DonationsUsersEdit  = ({ id, ...props }) => {

  const translate = useTranslate();        
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  let strRedirect =  `/donations_users/${id}`;    
    
  const onSuccess = ({ data }) => {
    notify(translate('common.notification.updated'));        
    redirect('/donations_users');        
  };
        
  return (

    <Edit 
      title={<ElementTitle {...props}  />} 
      {...props} 
      id={id}
      mutationMode="pessimistic"
      actions={<MyActions {...props} />}
      onSuccess={onSuccess}   
    >
        
      <TabbedForm        
        toolbar={<FormToolbar />}
      >    
        <FormTab
          label={translate('classrooms.data')}
          contentClassName={classes.tab}
          path="" 
                
        > 
               
          <FormDataConsumer>
            {({ formData, ...rest }) => 
              <>                              
                <br/>
                <Grid container spacing={2}>
                  <Grid item xs={3}>   
            <TextInput fullWidth source="donations_projects_id" label={translate('donations_users.donations_projects_id')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
            <TextInput fullWidth source="user_type" label={translate('donations_users.user_type')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
            <TextInput fullWidth source="first_name" label={translate('donations_users.first_name')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
            <TextInput fullWidth source="last_name_1" label={translate('donations_users.last_name_1')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
            <TextInput fullWidth source="last_name_2" label={translate('donations_users.last_name_2')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
            <TextInput fullWidth source="email" label={translate('donations_users.email')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
            <TextInput fullWidth source="nif" label={translate('donations_users.nif')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
            <TextInput fullWidth source="address" label={translate('donations_users.address')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
            <TextInput fullWidth source="zip" label={translate('donations_users.zip')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
            <TextInput fullWidth source="city" label={translate('donations_users.city')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
            <TextInput fullWidth source="country" label={translate('donations_users.country')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
            <TextInput fullWidth source="payment_systems_id" label={translate('donations_users.payment_systems_id')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
            <NumberInput fullWidth source="amount" label={translate('donations_users.amount')}  />
                  </Grid> 
                  <Grid item xs={3}>   
            <TextInput fullWidth source="status" label={translate('donations_users.status')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
            <TextInput fullWidth source="transaction_id" label={translate('donations_users.transaction_id')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
            <TextInput fullWidth source="comment" label={translate('donations_users.comment')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={12}>   
                  </Grid> 
                </Grid>                                

              </>
            }
          </FormDataConsumer>

        </FormTab> 
        
    
      </TabbedForm>
    </Edit>
  );
};

const ElementTitle = ({ record, ...props }) => {    
  const translate = useTranslate(); 
  return (<span>{translate('common.edit')} {record ? `"${record.name}"` : ''}</span>);
};
*/


const FormToolbar = ({ id , ...props }) => {
    
  let redirect = '/donations_users';
  
  return (
    <Toolbar {...props} className="user_form_toolbar" >
      <SaveButton />
      <DeleteButton redirect={redirect} mutationMode="pessimistic" />
    </Toolbar>
  );
};

const useStyles = makeStyles({
  comment: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    maxWidth: '100%',
    display: 'block',
  },
});






export const DonationsUsersCreate  = ({ id, ...props }) => {

  const translate = useTranslate();   
  const [info, setInfo] = useState();
    
  return (

    <Create {...props} >
      <SimpleForm 
        initialValues={{ associations_id: localStorage.getItem('associations_id') }}
        redirect="list"
      >                
        <Grid container spacing={2}>
          <Grid item xs={12}>                    
            <Typography variant="h5" className="grey">{translate('donations_users.create')}</Typography>
          </Grid>  
          <Grid item xs={11}>                    
            <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
          </Grid>          
        </Grid>   

      </SimpleForm> 
    </Create>
  );
};
