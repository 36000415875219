import React, { useState, useEffect } from 'react';

import { useListContext,      
  Button, FormWithRedirect, TextInput, SaveButton, SimpleFormIterator, ArrayInput,required , SelectInput, FormDataConsumer, DateInput, NumberInput, ReferenceInput, useDataProvider } from 'react-admin';


import { useTranslate  } from '../common/language';


import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import IconCancel from '@material-ui/icons/Cancel';


import Grid from '@mui/material/Grid';


import ContentFilter from '@mui/icons-material/FilterList';


import { validateMaxLength  } from './../components/Validator';

import { fetchCustomFields } from '../components/CustomFields';

import { subscriptionModule  } from '../common/utils';
import {modules,targets, choicesDelegations,choicesProperty,choicesCession,
  choicesClassroomsType,choicesYears,choicesShowsType, choicesRemittancesStatus,
  choicesRegion, choicesProvince, choicesCountry, choicesTrainingsType0,choicesTrainingsType1,
  choicesTrainingsFormal,choicesTrainingsFormalArt, choicesSocialProjects, choicesTypeUser, choicesGenderUser } from './../common/constants';

export const MyFilterUsers = (props) => {
    
  const [showDialog, setShowDialog] = useState(false);
  const translate = useTranslate();
  
  const dataProvider = useDataProvider();  
  const [customFields, setCustomFields] = useState();  
  const [federationCustomFields, setFederationCustomFields] = useState();  
  const [error, setError] = useState(false);  
    
  const {
    // fetched data
    data, // an array of the list records, e.g. [{ id: 123, title: 'hello world' }, { ... }]
    total, // the total number of results for the current filters, excluding pagination. Useful to build the pagination controls, e.g. 23      
    isFetching, // boolean that is true while the data is being fetched, and false once the data is fetched
    isLoading, // boolean that is true until the data is available for the first time
    // pagination
    page, // the current page. Starts at 1
    perPage, // the number of results per page. Defaults to 25
    setPage, // a callback to change the page, e.g. setPage(3)
    setPerPage, // a callback to change the number of results per page, e.g. setPerPage(25)
    hasPreviousPage, // boolean, true if the current page is not the first one
    hasNextPage, // boolean, true if the current page is not the last one
    // sorting
    sort, // a sort object { field, order }, e.g. { field: 'date', order: 'DESC' }
    setSort, // a callback to change the sort, e.g. setSort({ field: 'name', orfer: 'ASC' })
    // filtering
    filterValues, // a dictionary of filter values, e.g. { title: 'lorem', nationality: 'fr' }
    displayedFilters, // a dictionary of the displayed filters, e.g. { title: true, nationality: true }
    setFilters, // a callback to update the filters, e.g. setFilters(filters, displayedFilters)
    showFilter, // a callback to show one of the filters, e.g. showFilter('title', defaultValue)
    hideFilter, // a callback to hide one of the filters, e.g. hideFilter('title')
    // record selection
    selectedIds, // an array listing the ids of the selected rows, e.g. [123, 456]
    onSelect, // callback to change the list of selected rows, e.g. onSelect([456, 789])
    onToggleItem, // callback to toggle the selection of a given record based on its id, e.g. onToggleItem(456)
    onUnselectItems, // callback to clear the selection, e.g. onUnselectItems();
    // misc
    defaultTitle, // the translated title based on the resource, e.g. 'Posts'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    refetch, // callback for fetching the list data again
  } = useListContext();
    
  const fieldsString = [1,2,3,4,6,7,8,9,10,11,12,13,18,19,22,23,24,25,27,29,34,36,37,41,80,81,82,99,100,101,102,105,114,132,135,141];
  const fieldsDate = [14,15,33,110,111,140];
  const fieldsNumber = [16,17,
    115,116,117,118,119,120,
    201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,
    301,302,303,304,305,306,307,308,309,310,311,312,313,314,315,316,317,318,319,320,321,322,323,324,325,326,327,
    351,352,353,354,355,356,
    401,402,403,404,405,406,407,408,409,410,
    451,452,453,454,455,456,457,458,459,460,
    470,471,472,473,474,475,476,
    480,481,482,483,484,485,486,
    490,491,
    501,502,503,504,
    601,602,603,604,605,606,
    701,702,703];
  const fieldsBoolean = [28,30,103,104,106,107,108,109,113,121,122,123,125,126,127,130,131,133,134,136,137,138,139,750,751,752,50,51];    
    
  const fieldsUserType = [5];
  const fieldsQuote = [20];
  const fieldsRemittance = [21];
  const fieldsGender = [26];
  const fieldsDocs = [31,32,40];
  const fieldsNets = [35];  
  const fieldsProperty = [128];
  const fieldsCession = [129];
  const fieldsDelegation = [112];
  const fieldsClassrooms = [200];
  const fieldsEconomicData = [300];
  const fieldsDemografy = [350];
  const fieldsWorkers = [400,450];
  const fieldsActivities = [500];
  const fieldsActivitiesSelect = [503,504];
  const fieldsShows = [600];
  const fieldsShowsSelect = [604,605,606]; 
  const fieldsSections = [700];
  const fieldsSectionsSelect = [703];
  const fieldsCensusSelect = [800];
  const fieldsBooksSelect = [801];
  const fieldsMailingListsSelect = [802];
  const fieldsClassroomsTypeSelect = [202,203];
  const fieldsTrainingsType0Select = [471,473,475];
  const fieldsTrainingsType1Select = [481,483,485];
  const fieldsTrainingsFormalArtSelect = [472,474,476];
  const fieldsTrainingsFormalSelect = [482,484,486];
  const fieldsSocialProjecteTypeSelect = [491];
  const fieldsGroupSelect = [38];
  const fieldsRegionSelect = [83,89];
  const fieldsProvinceSelect = [84,90];
  const fieldsCountrySelect = [85,91];
  const fieldsRecercatCenterSelect = [39];

  const defaultYear = new Date().getFullYear()-1;
    
  useEffect(() => {
      
    if (subscriptionModule(modules.CUSTOM_FIELDS))  
    {
        fetchCustomFields(localStorage.getItem('associations_id'), targets.USER, setCustomFields, setError, dataProvider, false, false );
        
        if (subscriptionModule(modules.FEDERATION))
        {
            fetchCustomFields(localStorage.getItem('associations_id'), targets.USER_FEDERATION, setFederationCustomFields, setError, dataProvider, false, false );
        }
    }
    
  }, []);

  if (subscriptionModule(modules.CUSTOM_FIELDS) && !customFields) return null;
  if (subscriptionModule(modules.CUSTOM_FIELDS) && subscriptionModule(modules.FEDERATION) && !federationCustomFields) return null;
    
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
    
  const handleSubmit = async values => {                
    setFilters( { q:filterValues.q ? filterValues.q : ''  , ...values } );        
    setShowDialog(false);
  };
    
  const today = new Date().toISOString().slice(0, 10);
  let button_title = filterValues.filter && filterValues.filter.length > 0 ? translate('users_filter.filters') + ' (' + filterValues.filter.length + ')' : translate('users_filter.filters') ;
    
  let initialValues = filterValues && filterValues.filter && filterValues.filter.length ? filterValues : { filter: [ {f:0,o:1,v:''} ] };
    
  return (   
    <>
      <Button
        size="small"
        color="primary"
        onClick={handleClick}
        startIcon={<ContentFilter />}
        label={button_title}
      >
      </Button>
        
      <Dialog            
        open={showDialog}
        onClose={handleCloseClick}
        className="dialog-lg"
        maxWidth="xl"
      >
        <DialogTitle>{translate('users_filter.title')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={initialValues}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}> 
                    <ArrayInput source="filter" label="" >
                      <SimpleFormIterator
                        disableReordering
                        inline
                        className="simple-iterator-inline" 
                        getItemLabel={index => ''}
                      >
                        <FormDataConsumer>
                          {({ formData, scopedFormData, getSource, ...rest }) => true &&
                                            <>
                                            
                                              {/* TIPUS DE FILTRE */}
                                              <SelectInput label={translate('users_filter.field')} source={getSource('f')} choices={filterChoice(translate,props.group,customFields, federationCustomFields)} validate={[required()]} />
                                            
                                              {/* ANY */}
                                              { scopedFormData && scopedFormData.f && scopedFormData.f >= 300 && scopedFormData.f < 800 ?
                                                <SelectInput label={translate('users_filter.year')} source={getSource('y')} choices={choicesYears(translate)} validate={[required()]} initialValue={defaultYear} />
                                                : null } 
                                            
                                              {/* Activities */}
                                              { scopedFormData && scopedFormData.f && fieldsActivitiesSelect.includes(scopedFormData.f) ?
                                                <ReferenceInput label={translate('users_activities.name')} source={getSource('a')} reference="activities_users" perPage={1000} sort={{ field: 'priority', order: 'ASC' }} >
                                                  <SelectInput optionText="name" optionValue="id" validate={required()} resettable />
                                                </ReferenceInput>      
                                                : null } 
                                              {/* Shows */}
                                              { scopedFormData && scopedFormData.f && fieldsShowsSelect.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('users_shows.type')} source={getSource('s')} choices={choicesShowsType(translate)} validate={[required()]} initialValue={1} />                                            
                                                : null } 
                                              {/* Sections */}
                                              { scopedFormData && scopedFormData.f && fieldsSectionsSelect.includes(scopedFormData.f) ?
                                                <ReferenceInput label={translate('users_sections.name')} source={getSource('t')} reference="sections_users" perPage={1000} sort={{ field: 'priority', order: 'ASC' }} >
                                                  <SelectInput optionText="name" optionValue="id" validate={required()} resettable />
                                                </ReferenceInput>      
                                                : null } 
                                              {/* Census */}
                                              { scopedFormData && scopedFormData.f && fieldsCensusSelect.includes(scopedFormData.f) ?
                                                <ReferenceInput label={translate('users_federation.census')} source={getSource('c')} reference="census" perPage={1000} sort={{ field: 'priority', order: 'ASC' }} >
                                                  <SelectInput optionText="name" optionValue="id" validate={required()} resettable />
                                                </ReferenceInput>      
                                                : null } 
                                              {/* Books */}
                                              { scopedFormData && scopedFormData.f && fieldsBooksSelect.includes(scopedFormData.f) ?
                                                <ReferenceInput label={translate('users_federation.books')} source={getSource('b')} reference="books" perPage={1000} sort={{ field: 'priority', order: 'ASC' }} >
                                                  <SelectInput optionText="name" optionValue="id" validate={required()} resettable />
                                                </ReferenceInput>  
                                                : null } 
                                              {/* MailingLists */}
                                              { scopedFormData && scopedFormData.f && fieldsMailingListsSelect.includes(scopedFormData.f) ?
                                                <ReferenceInput label={translate('users_federation.mailing_lists')} source={getSource('m')} reference="mailing_lists" perPage={1000} sort={{ field: 'priority', order: 'ASC' }} >
                                                  <SelectInput optionText="name" optionValue="id" validate={required()} resettable />
                                                </ReferenceInput>  
                                                : null } 
                                              {/* Classrooms type */}
                                              { scopedFormData && scopedFormData.f && fieldsClassroomsTypeSelect.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('classrooms.type')} source={getSource('l')} choices={choicesClassroomsType(translate)} validate={[required()]} initialValue={1} />                                            
                                                : null }     
                                                
                                              {/* Tipus de formació en arts */}
                                              { scopedFormData && scopedFormData.f && fieldsTrainingsType0Select.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('users_trainings.type')} source={getSource('d')} choices={choicesTrainingsType0(translate)} validate={[required()]} initialValue={0} />                                            
                                                : null }    
                                              {/* Tipus de formació Formal/No formal */}
                                              { scopedFormData && scopedFormData.f && fieldsTrainingsFormalArtSelect.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('users_trainings.formal')} source={getSource('h')} choices={choicesTrainingsFormalArt(translate)} validate={[required()]} initialValue={0} />                                            
                                                : null } 
                                              {/* Tipus de formació */}
                                              { scopedFormData && scopedFormData.f && fieldsTrainingsType1Select.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('users_trainings.type')} source={getSource('e')} choices={choicesTrainingsType1(translate)} validate={[required()]} initialValue={0} />                                            
                                                : null }    
                                              {/* Tipus de formació Formal/No formal */}
                                              { scopedFormData && scopedFormData.f && fieldsTrainingsFormalSelect.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('users_trainings.formal')} source={getSource('h')} choices={choicesTrainingsFormal(translate)} validate={[required()]} initialValue={0} />                                            
                                                : null } 
                                              {/* Tipus de projecte social */}
                                              { scopedFormData && scopedFormData.f && fieldsSocialProjecteTypeSelect.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('users_social_projects.type')} source={getSource('i')} choices={choicesSocialProjects(translate)} validate={[required()]} initialValue={0} />                                            
                                                : null } 
                                            
                                              {/* OPERADOR */}
                                              {/* Data */}
                                              { scopedFormData && scopedFormData.f && (fieldsDate.includes(scopedFormData.f) || checkCustomFieldType(customFields,federationCustomFields,scopedFormData.f,[5]) ) ?
                                                <SelectInput label={translate('users_filter.operator')} source={getSource('o')} choices={filterOperations(translate,[2,3,4,5,6,7])} validate={[required()]} initialValue={2} />
                                                : null }   
                                              {/* String */}
                                              { scopedFormData && scopedFormData.f && (fieldsString.includes(scopedFormData.f) || checkCustomFieldType(customFields,federationCustomFields,scopedFormData.f,[1,2,3]) ) ?
                                                <SelectInput label={translate('users_filter.operator')} source={getSource('o')} choices={filterOperations(translate,[1,2,3,8])} validate={[required()]} initialValue={1} />
                                                : null }   
                                              {/* Boolean & selects */}
                                              { scopedFormData && scopedFormData.f && (fieldsBoolean.includes(scopedFormData.f) ||
                                                    fieldsUserType.includes(scopedFormData.f) ||
                                                    fieldsQuote.includes(scopedFormData.f) ||
                                                    fieldsRemittance.includes(scopedFormData.f) ||
                                                    fieldsProperty.includes(scopedFormData.f) ||
                                                    fieldsCession.includes(scopedFormData.f) ||   
                                                    fieldsDelegation.includes(scopedFormData.f) || 
                                                    fieldsRegionSelect.includes(scopedFormData.f) || 
                                                    fieldsProvinceSelect.includes(scopedFormData.f) || 
                                                    fieldsCountrySelect.includes(scopedFormData.f) || 
                                                    fieldsRecercatCenterSelect.includes(scopedFormData.f) || 
                                                    fieldsGender.includes(scopedFormData.f) ||
                                                    checkCustomFieldType(customFields,federationCustomFields,scopedFormData.f,[6,7,8]) 
                                                    ) ?
                                                <SelectInput label={translate('users_filter.operator')} source={getSource('o')} choices={filterOperations(translate,[2,3])} validate={[required()]} initialValue={2} />
                                                : null }   
                                              {/* Number */}
                                              { scopedFormData && scopedFormData.f && (fieldsNumber.includes(scopedFormData.f) || checkCustomFieldType(customFields,federationCustomFields,scopedFormData.f,[4]) ) ?
                                                <SelectInput label={translate('users_filter.operator')} source={getSource('o')} choices={filterOperations(translate,[2,3,4,5,6,7])} validate={[required()]} initialValue={4} />
                                                : null }   
                                              {/* Docs & nets */}
                                              { scopedFormData && scopedFormData.f && (fieldsDocs.includes(scopedFormData.f) || 
                                                    fieldsNets.includes(scopedFormData.f) || 
                                                    fieldsClassrooms.includes(scopedFormData.f) || 
                                                    fieldsWorkers.includes(scopedFormData.f) ||
                                                    fieldsActivities.includes(scopedFormData.f) ||
                                                    fieldsShows.includes(scopedFormData.f) ||
                                                    fieldsSections.includes(scopedFormData.f) ||
                                                    fieldsCensusSelect.includes(scopedFormData.f) ||
                                                    fieldsBooksSelect.includes(scopedFormData.f) ||
                                                    fieldsMailingListsSelect.includes(scopedFormData.f) ||
                                                    fieldsGroupSelect.includes(scopedFormData.f) ||
                                                    fieldsEconomicData.includes(scopedFormData.f)||
                                                    fieldsDemografy.includes(scopedFormData.f) ) ?
                                                <SelectInput label={translate('users_filter.operator')} source={getSource('o')} choices={[
                                                  { id: 9, name: translate('users_filter.filter_op_9') },
                                                  { id: 10, name: translate('users_filter.filter_op_10') }
                                                ]} validate={[required()]} initialValue={9} />
                                                : null }  
                                            
                                              {/* VALOR */}
                                              {/* Data */}
                                              { scopedFormData && scopedFormData.f && (fieldsDate.includes(scopedFormData.f) || checkCustomFieldType(customFields,federationCustomFields,scopedFormData.f,[5]) ) ?
                                                <DateInput label={translate('users_filter.value')} source={getSource('v')} validate={[required()]} initialValue={today} />
                                                : null }   
                                              {/* String */}
                                              { scopedFormData && scopedFormData.f && (fieldsString.includes(scopedFormData.f) || checkCustomFieldType(customFields,federationCustomFields,scopedFormData.f,[1,2,3]) ) ?
                                                <TextInput label={translate('users_filter.value')} source={getSource('v')}  validate={[validateMaxLength(30,translate('common.validation.maxLength'))]} />
                                                : null } 
                                              {/* Boolean */}
                                              { scopedFormData && scopedFormData.f && (fieldsBoolean.includes(scopedFormData.f) || checkCustomFieldType(customFields,federationCustomFields,scopedFormData.f,[6]) )?
                                                <SelectInput label={translate('users_filter.value')} source={getSource('v')} choices={filterBooleanChoice(translate)} validate={[required()]} initialValue={1} />
                                                : null } 
                                              {/* Number */}
                                              { scopedFormData && scopedFormData.f && (fieldsNumber.includes(scopedFormData.f) || checkCustomFieldType(customFields,federationCustomFields,scopedFormData.f,[4])) ?
                                                <NumberInput label={translate('users_filter.value')} source={getSource('v')} validate={[required(),validateMaxLength(10,translate('common.validation.maxLength'))]} />
                                                : null } 
                                              {/* fieldsUserType */}
                                              { scopedFormData && scopedFormData.f && fieldsUserType.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('users_filter.value')} source={getSource('v')} choices={choicesTypeUser(translate)} validate={[required()]} initialValue={1} />
                                                : null } 
                                              {/* fieldsQuote */}
                                              { scopedFormData && scopedFormData.f && fieldsQuote.includes(scopedFormData.f) ?
                                                <ReferenceInput label={translate('users_groups_quotes.group_quote')} source={getSource('v')} reference="groups_quotes" filter={{ groups_id : props.group }} perPage={1000} >
                                                  <SelectInput optionText="name" optionValue="id" validate={required()} resettable />
                                                </ReferenceInput>                                             
                                                : null } 
                                              {/* fieldsRemittance */}
                                              { scopedFormData && scopedFormData.f && fieldsRemittance.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('users_filter.value')} source={getSource('v')} choices={choicesRemittancesStatus(translate)} validate={[required()]} initialValue={1} />
                                                : null } 
                                              {/* fieldsGender */}
                                              { scopedFormData && scopedFormData.f && fieldsGender.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('users_filter.value')} source={getSource('v')} choices={choicesGenderUser(translate)} validate={[required()]} initialValue={1} />
                                                : null } 
                                              {/* fieldsNets */}
                                              { scopedFormData && scopedFormData.f && fieldsNets.includes(scopedFormData.f) ?
                                                <ReferenceInput label={translate('social_nets.title')} source={getSource('v')} reference="nets" perPage={1000} >
                                                  <SelectInput optionText="name" optionValue="id" validate={required()} resettable />
                                                </ReferenceInput> 
                                                : null } 
                                              {/* fieldsProperty */}
                                              { scopedFormData && scopedFormData.f && fieldsProperty.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('users_filter.value')} source={getSource('v')} choices={choicesProperty} validate={[required()]} initialValue={1} />                                            
                                                : null } 
                                              {/* fieldsCession */}
                                              { scopedFormData && scopedFormData.f && fieldsCession.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('users_filter.value')} source={getSource('v')} choices={choicesCession} validate={[required()]} initialValue={1} />                                            
                                                : null }  
                                              {/* fieldsDelegation */}
                                              { scopedFormData && scopedFormData.f && fieldsDelegation.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('users_filter.value')} source={getSource('v')} choices={choicesDelegations} validate={[required()]} initialValue={1} />                                            
                                                : null } 
                                              {/* fieldsClassroooms */}
                                              { scopedFormData && scopedFormData.f && fieldsClassrooms.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('users_filter.value')} source={getSource('v')} choices={choicesClassroomsType(translate)} validate={[required()]} initialValue={1} />                                            
                                                : null } 
                                              {/* fieldsGroupSelect */}
                                              { scopedFormData && scopedFormData.f && fieldsGroupSelect.includes(scopedFormData.f) ?
                                                <ReferenceInput label={translate('users.group')} source={getSource('g')} reference="groups" filter={{ associations_id : localStorage.getItem('associations_id') }} perPage={1000} >
                                                  <SelectInput optionText="path" optionValue="id" validate={required()} resettable />
                                                </ReferenceInput> 
                                                : null }  
                                              {/* fieldsRegion */}
                                              { scopedFormData && scopedFormData.f && fieldsRegionSelect.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('users_filter.value')} source={getSource('r')} choices={choicesRegion} validate={[required()]} initialValue={1} />                                            
                                                : null } 
                                              {/* fieldsProvince */}
                                              { scopedFormData && scopedFormData.f && fieldsProvinceSelect.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('users_filter.value')} source={getSource('p')} choices={choicesProvince} validate={[required()]} initialValue={1} />                                            
                                                : null } 
                                              {/* fieldsCountry */}
                                              { scopedFormData && scopedFormData.f && fieldsCountrySelect.includes(scopedFormData.f) ?
                                                <SelectInput label={translate('users_filter.value')} source={getSource('u')} choices={choicesCountry} validate={[required()]} initialValue={1} />                                            
                                                : null } 
                                              {/* fieldsRecercatCenters */}
                                              { scopedFormData && scopedFormData.f && fieldsRecercatCenterSelect.includes(scopedFormData.f) ?
                                                <ReferenceInput label={translate('recercat_centers.column_center')} source={getSource('v')} reference="recercat_centers" filter={{ associations_id : localStorage.getItem('associations_id') }} perPage={10000} >
                                                  <SelectInput optionText="name" optionValue="id" validate={required()} resettable />
                                                </ReferenceInput> 
                                                : null } 
                                              
                                              { /* CUSTOM FIELDS */ }
                                              { scopedFormData && scopedFormData.f && checkCustomFieldType(customFields,federationCustomFields,scopedFormData.f,[7,8]) ?
                                              <SelectInput label={translate('users_filter.value')} source={getSource('j')} choices={getChoices(customFields,federationCustomFields,scopedFormData.f)} validate={[required()]} />                                                                                          
                                              : null }
                                            
                                            </>
                          }
                        </FormDataConsumer>
                                        
                      </SimpleFormIterator>                                   
                    </ArrayInput>
                  </Grid> 
                </Grid>
                            
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};
        
const checkCustomFieldType = (customFields,federationCustomFields,id,typeList) => {
    let found = false;
    let ct_id = id - 1000000;
    if (customFields)
    {
        customFields.map((element, index) => {
            if (element.id == ct_id && typeList.includes(element.type)) found = true;
        });  
    }
    if (federationCustomFields)
    {
        federationCustomFields.map((element, index) => {
            if (element.id == ct_id && typeList.includes(element.type)) found = true;
        });  
    }
    return found;
}

const getChoices = (customFields,federationCustomFields,id) => {
    
    let ct_id = id - 1000000;
    
    for (let index = 0; index < customFields.length; index++) {
        let element = customFields[index];

        if (element.id == ct_id) {
            return element.choices;
        }
    }
    
    for (let index = 0; index < federationCustomFields.length; index++) {
        let element = federationCustomFields[index];

        if (element.id == ct_id) {
            return element.choices;
        }
    }
    
    return [];
}

const filterChoice = (translate,group,customFields,federationCustomFields) => {

  const filter = [];    
  let i = 1;
    
  for (i=1;i<=4;i++)
    filter.push({ id: i, name: translate('users_filter.filter_' + i) });
  
  if ( !subscriptionModule(modules.RECERCAT) )
  {
      filter.push({ id: 41, name: translate('users_filter.filter_41') });
  }
  
  if ( !subscriptionModule(modules.RECERCAT) )
  {
    for (i=5;i<=32;i++)
      filter.push({ id: i, name: translate('users_filter.filter_' + i) });
  
    filter.push({ id: 40, name: translate('users_filter.filter_40') });    
    
    if (customFields)
    {
        customFields.map((element, index) => {
            filter.push({ id: 1000000+element.id, name: element.name }); 
        });    
    }    

  }
    
  // FILTRES QUE HAN DE TENIR GRUP
  if (group !== 999999998 && group !== 999999999)
  {
    filter.push({ id: 33, name: translate('users_filter.filter_33') });
    filter.push({ id: 34, name: translate('users_filter.filter_34') });
        
    if ( !subscriptionModule(modules.RECERCAT) )
    {
      filter.push({ id: 50, name: translate('users_filter.filter_50') });
    }
        
    if ( subscriptionModule(modules.DIGITAL_CARDS) || subscriptionModule(modules.WALLETS) )
    {
      filter.push({ id: 51, name: translate('users_filter.filter_51') });
    }
  }
    
  if ( !subscriptionModule(modules.RECERCAT) )
  {
    for (i=35;i<=37;i++)
      filter.push({ id: i, name: translate('users_filter.filter_' + i) });
  }
        
  // FILTRES DEL GRUP TOTS
  if (group === 999999998)
  {
    filter.push({ id: 38, name: translate('users_filter.filter_38') });
  }
    
  if ( subscriptionModule(modules.RECERCAT) )
  {
    filter.push({ id: 39, name: translate('users_filter.filter_39') });
  }
      
  if ( subscriptionModule(modules.FEDERATION) )
  {
    for (i=80;i<=91;i++)
      filter.push({ id: i, name: translate('users_filter.filter_' + i) });
    
    for (i=99;i<=141;i++)
    {
      if (i != 115 && i != 116 && i != 117 && i != 118 && i != 119 && i != 120 && i != 124 ) filter.push({ id: i, name: translate('users_filter.filter_' + i) });
    }
    
    if (federationCustomFields)
    {
        federationCustomFields.map((element, index) => {
            filter.push({ id: 1000000+element.id, name: element.name }); 
        }); 
    }
        
    for (i=200;i<=203;i++)
      filter.push({ id: i, name: translate('users_filter.filter_' + i) });
        
    for (i=300;i<=327;i++)
      filter.push({ id: i, name: translate('users_filter.filter_' + i) });
  
    for (i=350;i<=356;i++)
      filter.push({ id: i, name: translate('users_filter.filter_' + i) });
        
    for (i=400;i<=410;i++)
      filter.push({ id: i, name: translate('users_filter.filter_' + i) });
        
    for (i=450;i<=460;i++)
      filter.push({ id: i, name: translate('users_filter.filter_' + i) });
    
    for (i=470;i<=476;i++)
      filter.push({ id: i, name: translate('users_filter.filter_' + i) });
  
    for (i=480;i<=486;i++)
      filter.push({ id: i, name: translate('users_filter.filter_' + i) });
  
    for (i=490;i<=491;i++)
      filter.push({ id: i, name: translate('users_filter.filter_' + i) });
        
    for (i=500;i<=504;i++)
      filter.push({ id: i, name: translate('users_filter.filter_' + i) });
        
    for (i=600;i<=606;i++)
      filter.push({ id: i, name: translate('users_filter.filter_' + i) });
      
    for (i=700;i<=703;i++)
      filter.push({ id: i, name: translate('users_filter.filter_' + i) });
        
    for (i=800;i<=802;i++)
      filter.push({ id: i, name: translate('users_filter.filter_' + i) });
          
    for (i=750;i<=752;i++)
      filter.push({ id: i, name: translate('users_filter.filter_' + i) });
  }
    
  return filter;    
};

function filterOperations (translate,operatorsToApply) {
  const filter = [];    
  let i = 1;
    
  //console.log(operatorsToApply);
    
  for (i=1;i<=9;i++)
  {
    if (operatorsToApply.includes(i))
      filter.push({ id: i, name: translate('users_filter.filter_op_' + i) });
  }    
  return filter;    
}

const filterBooleanChoice = (translate) => {
  return (
    [{id:1,name:translate('common.yes')},{id:0,name:translate('common.no')}]
  );
};


